import React from "react";
import { Box, Flex } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { BsArrowUpRight } from "react-icons/bs";
import "react-modern-drawer/dist/index.css";

import Drawer from "react-modern-drawer";

const MainDrawer = ({ isOpen, onClose }) => {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="top"
      style={{
        height: "fit-content",
        width: "100%",
        paddingBottom: "34px",
      }}
    >
      <Box fontFamily="DM Sans">
        <Flex
          pos="sticky"
          px="20px"
          pt="48px"
          top="0"
          pb="35px"
          justifyContent="space-between"
          align="center"
        >
          <Flex justifyContent="center" w="full" align="center">
            <Image
              w="120px"
              h="37px"
              objectFit="cover"
              src="/assets/logo.png"
            />
          </Flex>
        </Flex>

        <Box mt="50px" pb="10px">
          <Flex
            align="center"
            justifyContent="center"
            flexDir="column"
            gap="39px"
          >
            <a
              href="https://www.school.edupali.com/login"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="darkBg"
                bg="transparent"
                fontSize="18px"
                lineHeight="45px"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                display="flex"
                align="center"
                onClick={onClose}
                gap="10px"
              >
                School Login
                <BsArrowUpRight />
              </Button>
            </a>

            <a
              href="https://www.parent.edupali.com/login"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="darkBg"
                bg="transparent"
                fontSize="18px"
                lineHeight="45px"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                display="flex"
                onClick={onClose}
                align="center"
                gap="10px"
              >
                Parent Login
                <BsArrowUpRight />
              </Button>
            </a>

            <a
              href="https://docs.google.com/forms/u/0/d/1kjwdv8MbSFc_JoGR4sx1p8CMv8upb2sBd77u72h31u0/viewform?pli=1&pli=1&edit_requested=true"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="darkBg"
                bg="transparent"
                fontSize="18px"
                lineHeight="45px"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                display="flex"
                onClick={onClose}
                align="center"
                gap="10px"
              >
                Request Demo
                <BsArrowUpRight />
              </Button>
            </a>

            <a
              href="https://www.school.edupali.com/register-step-1"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <Button
                py="25px"
                px="40px"
                borderRadius="10px"
                onClick={onClose}
                display="flex"
                align="center"
                gap="18px"
              >
                Create a school account
                <BsArrowUpRight />
              </Button>
            </a>
          </Flex>
        </Box>

        <Flex flexDir="column" justifyContent="center" align="center">
          <Flex
            flexDir="column"
            justifyContent="center"
            align="center"
            mt="100px"
            onClick={onClose}
            cursor="pointer"
            w="fit-content"
            bg="#DFDFDF"
            p="2"
            rounded="full"
          >
            <MdClose size="40px" color="#fff" />
          </Flex>
        </Flex>
      </Box>
    </Drawer>
  );
};

export default React.memo(MainDrawer);
