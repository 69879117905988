import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { MdMenu } from "react-icons/md";
import MainDrawer from "./MainDrawer";
import { BsArrowUpRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [isMobile] = useMediaQuery("(max-width: 1226px)");
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  return (
    <Flex
      flexDir="column"
      w="full"
      align="center"
      justifyContent="center"
      pos="fixed"
      boxShadow="0px 4px 4px 0px rgba(234, 234, 234, 0.50)"
      top="0"
      zIndex={10}
      bg={scroll ? "rgba(255, 255, 255, 0.15)" : "#fff"}
      backdropFilter={"blur(10px)"}
    >
      <Box w={isMobile ? "100%" : "1226px"} px="20px" pt="22px" pb="14px">
        <Flex w="full" justifyContent="space-between" align="center">
          <Box w={{ base: "full", md: "unset" }}>
            <Image
              onClick={() => navigate("/")}
              cursor="pointer"
              w={isMobile ? "150px" : "200px"}
              h={isMobile ? "37px" : "62px"}
              objectFit="cover"
              src="/assets/logo.png"
            />
          </Box>

          <Flex
            display={isMobile ? "flex" : "none"}
            justifyContent="flex-end"
            w="full"
          >
            <MdMenu onClick={onOpen} color="#000" size="45px" />
          </Flex>

          <Flex align="center" gap="40px" display={isMobile ? "none" : "flex"}>
            <a
              href="https://www.school.edupali.com/login"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="darkBg"
                bg="transparent"
                fontSize="18px"
                lineHeight="45px"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                display="flex"
                align="center"
                gap="10px"
                className="btns"
              >
                School Login
                <BsArrowUpRight className="arrow" />
              </Button>
            </a>

            <a
              href="https://www.parent.edupali.com/login"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="darkBg"
                bg="transparent"
                fontSize="18px"
                lineHeight="45px"
                className="btns"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                display="flex"
                align="center"
                gap="10px"
              >
                Parent Login
                <BsArrowUpRight className="arrow" />
              </Button>
            </a>

            <a
              href="https://docs.google.com/forms/u/0/d/1kjwdv8MbSFc_JoGR4sx1p8CMv8upb2sBd77u72h31u0/viewform?pli=1&pli=1&edit_requested=true"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="darkBg"
                bg="transparent"
                fontSize="18px"
                lineHeight="45px"
                className="btns"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                display="flex"
                align="center"
                gap="10px"
              >
                Request Demo
                <BsArrowUpRight className="arrow" />
              </Button>
            </a>
          </Flex>

          <Flex align="center" display={isMobile ? "none" : "flex"} gap="30px">
            <a
              href="https://www.school.edupali.com/register-step-1"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                py="25px"
                px="32px"
                boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.10)"
                borderRadius="10px"
                className="btns"
                display="flex"
                align="center"
                gap="18px"
              >
                Create a school account
                <BsArrowUpRight className="arrow" />
              </Button>
            </a>
          </Flex>
        </Flex>
      </Box>
      <MainDrawer isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Header;
