import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { contact, company, connect } from "../components/common/constants";
import { Link as ReachLink } from "react-router-dom";

const Footer = () => {
  const year = new Date();

  const [isMobile] = useMediaQuery("(max-width: 1226px)");
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      align="center"
      w="full_width"
      pt="102px"
      bg="darkBg"
      pos="relative"
      color="#fff"
    >
      <Image
        display={isMobile ? "none" : "flex"}
        src="/assets/foot-icon.png"
        pos="absolute"
        left="0"
        top="0"
      />
      <Flex
        px={isMobile ? "45px" : "unset"}
        flexDir="column"
        align="center"
        justifyContent="center"
      >
        <Text
          w={isMobile ? "100%" : "45%"}
          fontSize="14px"
          lineHeight="30px"
          color="#FCFCFC"
          textAlign="center"
          fontFamily="Space Grotesk"
        >
          EduPali is a product offered by FastaMoni Technologies Inc. FastaMoni
          financial services are facilitated in partnership with URE
          Microfinance Bank Ltd, a licensed financial institution regulated by
          the Central Bank of Nigeria. Additionally, EduPali collaborates with
          other reputable financial service providers to ensure seamless and
          efficient payment solutions.
        </Text>

        <Flex
          flexDir="column"
          align="center"
          justifyContent="center"
          mt={isMobile ? "66px" : "29px"}
        >
          <Grid
            gap="30px"
            rowGap={isMobile ? "51px" : "28px"}
            templateColumns={[
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(4,1fr)",
              "repeat(4,1fr)",
            ]}
          >
            <GridItem colSpan={[1, 1, 1, 1]}>
              <Text
                fontSize="14px"
                color="#4A3AFF"
                fontWeight={500}
                lineHeight="30px"
              >
                Company
              </Text>
              {company?.map((item, i) => (
                <Box
                  zIndex={5}
                  pos="relative"
                  key={i}
                  fontSize="14px"
                  fontWeight={500}
                  lineHeight="30px"
                >
                  <ReachLink to={item.path} target="_blank">
                    {item.title}
                  </ReachLink>
                </Box>
              ))}
            </GridItem>

            <GridItem>
              <Text
                fontSize="14px"
                color="#4A3AFF"
                fontWeight={500}
                lineHeight="30px"
              >
                Contact
              </Text>
              {contact?.map((item, i) => (
                <Box
                  pos="relative"
                  zIndex={77}
                  key={i}
                  fontSize="14px"
                  fontWeight={500}
                  lineHeight="30px"
                >
                  <ReachLink to={item.path}>{item.name}</ReachLink>
                </Box>
              ))}
            </GridItem>

            <GridItem
              colSpan={isMobile ? 2 : 1}
              fontSize="14px"
              fontWeight={500}
              lineHeight="30px"
            >
              <Flex align="center" gap="15px">
                <Text>Delaware, USA</Text>
                <Image src="/assets/usa.png" />
              </Flex>
              <Text>FastaMoni Technologies Inc.</Text>
              <Text>1207 Delaware Ave #162 Wilmington, DE</Text>
              <Text>19806</Text>
            </GridItem>

            <GridItem
              colSpan={isMobile ? 2 : 1}
              fontSize="14px"
              fontWeight={500}
              lineHeight="30px"
            >
              <Flex align="center" gap="15px">
                <Text>Lagos, Nigeria</Text>
                <Image src="/assets/ngn.png" />
              </Flex>
              <Text>FastaMoni Technologies Inc.</Text>
              <Text>Top Notch Plaza, Abraham Adesanya, Ajah-</Text>
              <Text>Lekki, Lagos, Nigeria</Text>
            </GridItem>

            <GridItem
              fontSize="14px"
              colSpan={isMobile ? 2 : 1}
              fontWeight={500}
              lineHeight="30px"
            >
              <Text
                fontSize="14px"
                color="#4A3AFF"
                fontWeight={500}
                lineHeight="30px"
              >
                Connect
              </Text>

              <Flex mt="3px" gap="30px" align="center">
                {connect.map((data, i) => (
                  <a href={data?.link} target="_blank" rel="noreferrer">
                    <Image key={i} src={data?.img} w="26px" h="26px" />
                  </a>
                ))}
              </Flex>
            </GridItem>
          </Grid>
        </Flex>
      </Flex>

      <Flex
        borderTop="1px solid #8AABFF"
        mt="27px"
        justifyContent="center"
        fontSize="12px"
        lineHeight="20px"
        fontWeight={500}
        w="full"
        color="rgba(210, 223, 255, 0.53)"
        py="20px"
        align="center"
      >
        {year.getFullYear()} Copyright. All right reserved
      </Flex>
    </Flex>
  );
};

export default Footer;
