import React from "react";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import Footer from "./Footer";
import Header from "./Header";

export const Layout = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 1226px)");
  return (
    <Flex
      flexDir="column"
      overflow={{ base: "hidden", lg: "hidden" }}
      minH="100vh"
      fontFamily="DM Sans"
      bg="#FFF"
      w="full"
    >
      <Header />
      <Flex justifyContent="center" align="center">
        <Box
          w={isMobile ? "100%" : "1226px"}
          pt={isMobile ? "120px" : "160px"}
          px="20px"
        >
          {children}
        </Box>
      </Flex>
      <Box mt="auto">
        <Footer />
      </Box>
    </Flex>
  );
};
