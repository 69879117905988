export const avatars = [
  "/assets/av1.png",
  "/assets/av2.png",
  "/assets/av3.png",
  "/assets/av4.png",
  "/assets/av5.png",
  "/assets/av6.png",
];

export const reviews = [
  {
    id: 0,
    name: "Bolaji Ogunsola",
    desc: "EduPali transformed our fee collections! Parents love the convenience, and we now enjoy increased compliance rates. Highly recommended!",
  },
  {
    id: 1,
    name: "John Babatunde",
    desc: "Managing fees is a breeze with EduPali! The credit facility helps fund our expansion. Thrilled with the results!",
  },
  {
    id: 2,
    name: "Halima Apev",
    desc: "Parents appreciate the flexible payment plans. EduPali's support team is fantastic! A game-changer for our school.",
  },
  {
    id: 3,
    name: "Uduak Sunday",
    desc: "EduPali streamlines our financials. The real-time tracking and analytics make all the difference in our school's growth and productivity",
  },
  {
    id: 4,
    name: "Justice Nwachukwu",
    desc: "With EduPali, our fee collections are effortless. We can focus on education, knowing payments are in good hands.",
  },
  {
    id: 5,
    name: "Olurunshogo Akintunde",
    desc: "The quick credit facility from EduPali was a lifesaver during tough times. Grateful for their financial support.",
  },
  {
    id: 6,
    name: "Bukola Matthews",
    desc: "EduPali is a ultimate solution for our school's finances. The platform is user-friendly, and our parents love it!",
  },
];

export const activeStyle = {
  backgroundColor: "#182444",
  alignItems: "center",
  display: "flex",
  color: "#fff",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "21px",
  margin: "0 -20px 8px",
  padding: "13px 25px",
  borderRight: "4px solid #2463EB",
};

export const faqs = [
  {
    id: 1,
    ques: "How do i register my school?",
    ans: "Registering is as easy as ABC. Before you start, get these together:\nA valid and active email address.Your school name (of course you know that already). The CAC registration number of your school (BN or RC).Number of students enrolled in your school.Then click on www.edupali.com Look for the 'School Page', click on the 'Sign Up' button, and fill in your personal details. On the second page, fill as prompted.That's all!",
  },
  {
    id: 1,
    ques: "How do i register my child?",
    ans: "I'm afraid EduPali works directly with school owners, but that doesn't mean you can request them to join, just for your sake.\nCall them; or we can do the calling for you.\nPlease drop the name of the school and address here on support@fastamoni.com. We won't mind having a number to call. \nThank you!",
  },
  {
    id: 2,
    ques: "How soon will I get the loans?",
    ans: "Schools can access loans almost immediately, however there are steps to qualify. \n• Complete profile\n• Enrolled parents and students to the portal \n• Parents have subscribed to a payment plan\nOurs is a short term facility loan; repayment can be completed before the end of the term.",
  },
  {
    id: 4,
    ques: "Can I pay with cash?",
    ans: "Conveniently pay with cash using your MoMo wallet. To create one, simply dial *671# using any mobile number. Choose a four-digit pin, and there you have it!. To top up your account, visit any MTN MoMo agent around you",
  },
  {
    id: 5,
    ques: "Is EduPali regulated?",
    ans: "EduPali is a brain-child of FastaMoni Technologies Inc. FastaMoni is powered by URE MFB, a licensed financial institution by the Central Bank of Nigeria.\nWe are covered, just as we've got you covered.",
  },
];

export const company = [
  { title: "About", path: "/about-us" },
  { title: "Privacy Policy", path: "/" },
  { title: "Terms & Condition", path: "/" },
];

export const contact = [
  {
    id: 1,
    name: "USA: +1(302) 566-4192",
    path: "tel: +13025664192",
  },
  {
    id: 2,
    name: "NGN: +234 810 012 1160",
    path: "tel: +23478100121160",
  },
  {
    id: 3,
    name: "NGN: +234 01 229 6204",
    path: "tel: ++234012296204",
  },
  {
    id: 4,
    name: "support@fastamoni.com",
    path: "mailto:support@fastamoni.com",
  },
];

export const connect = [
  { img: "/assets/ig.svg", link: "https://instagram.com/edupalihq" },
  { img: "/assets/fb.svg", link: "https://www.facebook.com/fastamoni" },
  {
    img: "/assets/linkedin.svg",
    link: "https://www.linkedin.com/showcase/edupali/",
  },
  { img: "/assets/twitter.svg", link: "https://twitter.com/edu_pali" },
];

//NEW SECTION
export const heroSlides = [
  {
    id: 0,
    title: "Low compliance rates",
    text: "(Raise your compliance rates by 95%)",
  },
  {
    id: 1,
    title: "Hectic follow-ups",
    text: "(Restrict calls to just success news of your students)",
  },
  {
    id: 2,
    title: "Insufficient school funds",
    text: "(Access loans to fund school projects)",
  },
  {
    id: 3,
    title: "Missing tellers and alerts",
    text: "(Get up-to-date information on all payments and transactions)",
  },
  {
    id: 4,
    title: "Bulk school fees payment",
    text: "(Control how you pay school fees with our flexible payment plan)",
  },
];

export const statistics = [
  {
    title: "600+",
    text: "Schools",
  },
  {
    title: "150,000+",
    text: "Students",
  },
  {
    title: "190,500+",
    text: "Payments",
  },
];

export const benefit = [
  "Offer parents flexible and affordable payment plan choices",
  "Raise compliance rates by up to 95% within 90 days",
  "Keep better track of all payments history: no stories",
  " Access up to 20M Naira loan to fund school operations and projects",
  "Relieve parents of the hassle of bulk payments.",
  "Expand your school population with the help of our innovative solutions.",
  "End debt follow-up calls with parents; our professionals will handle that",
  "Foster better relationships with parents: no more frowns and cringes",
  "Reduce the risk of student cross-carpeting; be certain of your revenue",
];

export const parBenefit = [
  "Automate school fees payments with our daily, weekly and monthly plans",
  "Easily make full payment in advance for the entire term or academic year",
  "Receive discounts for making full payment of the fees",
  "Avoid embarrassing debt follow-up calls from schools",
  "Ensure an uninterrupted learning experience for your children",
  "Enjoy rebates for high payment compliance rates",
  "Stay informed with real-time payment updates and transaction history",
];

export const keyFeatures = [
  {
    img: "/assets/ft1.png",
    title: "Flexible and Affordable Payment Plans",
    desc: "We understand that parents appreciate flexibility in managing their financial commitments. EduPali offers affordable payment plan options that suit diverse budgets, enabling parents to meet their financial obligations and provide quality education for their children.",
  },
  {
    img: "/assets/ft2.png",
    title: "Access Quick Credit Loans",
    desc: "Acknowledging the economic challenges faced by schools,EduPali offers quick credit loan facilities of up to 20 million Naira. This financial support empowers schools to fund their day-to-day operations and pursue expansion opportunities without unnecessary financial strain.",
  },
  {
    img: "/assets/ft3.png",
    title: "Increase Payment Compliance Rate",
    desc: "EduPali assists schools in improving payment compliance rates by providing parents with convenient and flexible fee payment options. Our user-friendly interface ensures easy access and use. Every parent can now settle fees on time thereby reducing administrative burdens for schools.",
  },
  {
    img: "/assets/ft4.png",
    title: "Fraud Protection",
    desc: "With our robust system, EduPali safeguards schools from student cross-carpeting fraud, ensuring that schools do not lose vital revenue. Our secure platform offers peace of mind to client-schools allowing them to focus on their core mission of providing excellent education.",
  },
];
