import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
    }
    
    @font-face {
      font-family: 'Futu';
      src: url('/fonts/FutuMd.ttf');
      src:
        local('FutuMd'),
        url('/fonts/FutuMd.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
    
    @font-face {
      font-family: 'Futu';
      src: url('/fonts/FutuBoldBT.ttf');
      src:
        local('FutuBoldBT'),
        url('/fonts/FutuBoldBT.ttf') format('truetype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }
    
    // @font-face {
    //   font-family: 'Futu';
    //   src: url('/fonts/FutuMediumBT.ttf');
    //   src:
    //     local('FutuMediumBT'),
    //     url('/fonts/FutuMediumBT.ttf') format('truetype');
    //   font-weight: 500;
    //   font-style: normal;
    //   font-display: swap;
    // }
    `}
  />
);

export default Fonts;
