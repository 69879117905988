import React, { useEffect } from "react";
import Pages from "./routes";

const App = () => {
  const imagesToPreload = [
    "/assets/logo.png",
    "/assets/pattern.svg",
    "/assets/carpet.jpg",
    "/assets/credit.jpg",
    "/assets/epms.jpg",
    "/assets/graduate.jpg",
    "/assets/hero.jpg",
    "/assets/arrow.svg",
    "/assets/lines.svg",
    "/assets/av1.png",
    "/assets/av2.png",
    "/assets/av3.png",
    "/assets/av4.png",
    "/assets/av5.png",
    "/assets/av6.png",
    "/assets/ig.svg",
    "/assets/fb.svg",
    "/assets/linkedin.svg",
    "/assets/twitter.svg",
    "/assets/curve1.png",
    "/assets/curve2.png",
    "/assets/curve3.png",
    "/assets/ft1.png",
    "/assets/ft2.png",
    "/assets/foot-icon.png",
    "/assets/usa.png",
    "/assets/ngn.png",
    "/assets/acc-arrow.svg",
    "/assets/gif2.gif",
    "/assets/ft3.png",
    "/assets/ft3.png",
    "/assets/boy_gif.gif",
  ];

  const preloadImages = () => {
    imagesToPreload.forEach((imagePath) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = imagePath;
      link.as = "image";
      document.head.appendChild(link);
    });
  };

  useEffect(() => {
    preloadImages();
  }, []);

  return <Pages />;
};

export default App;
