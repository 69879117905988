import { lazy } from "react";
import WithSuspense from "../components/Loaders/WithSuspense";
import { PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const { HOME, CREDIT, CARPET, EPMS, ABOUT, PRIVACY } = PATHS;

const MainPage = WithSuspense(lazy(() => import("../pages/index")));
const Credit = WithSuspense(lazy(() => import("../pages/credit")));
const Carpet = WithSuspense(lazy(() => import("../pages/carpet")));
const Epms = WithSuspense(lazy(() => import("../pages/epms")));
const About = WithSuspense(lazy(() => import("../pages/about")));
const Privacy = WithSuspense(lazy(() => import("../pages/privacy")));

export const ROUTES = [
  { path: HOME, element: <MainPage /> },
  { path: CARPET, element: <Carpet /> },
  { path: CREDIT, element: <Credit /> },
  { path: EPMS, element: <Epms /> },
  { path: ABOUT, element: <About /> },
  { path: PRIVACY, element: <Privacy /> },
  { path: "*", element: <Navigate to="/" replace /> },
];
